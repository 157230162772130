import React from 'react'
import {graphql} from 'gatsby'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-images'
import Helmet from 'react-helmet'

import Layout from '../layout'
import SEO from '../components/seo'

class GalleryPage extends React.Component {
	state = {
		filter: `All`,
		currentImage: 0,
		lightboxIsOpen: false,
	}

	openLightbox(event, obj) {
		this.setState({
			currentImage: obj.index,
			lightboxIsOpen: true,
		})
	}

	closeLightbox() {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false,
		})
	}

	gotoPrevious() {
		this.setState({
			currentImage: this.state.currentImage - 1,
		})
	}

	gotoNext() {
		this.setState({
			currentImage: this.state.currentImage + 1,
		})
	}

	render() {
		const {data} = this.props
		const {filter, currentImage, lightboxIsOpen} = this.state

		const activate = filter => this.setState({filter})

		const activeItems =
			filter === `All`
				? data.gallery.items
				: data.gallery.items.filter(({type}) => type === filter)

		const photos = activeItems
			.filter(item => item.image?.file)
			.map(item => item.image.file)

		return (
			<Layout>
				<Helmet>
					<body className="gallery-page" />
				</Helmet>
				<SEO {...data.metadata} />
				<div
					id="filters-container"
					className="block-button-container cbp-l-filters-buttonCenter"
				>
					<FilterButton active={filter} onClick={activate}>
						All
					</FilterButton>
					<FilterButton active={filter} onClick={activate}>
						Behind the scenes
					</FilterButton>
					<FilterButton active={filter} onClick={activate}>
						Gear on set
					</FilterButton>
					<FilterButton active={filter} onClick={activate}>
						On the screen
					</FilterButton>
				</div>

				<Gallery
					photos={photos}
					direction="column"
					onClick={::this.openLightbox}
				/>
				<Lightbox
					images={photos}
					onClose={::this.closeLightbox}
					onClickPrev={::this.gotoPrevious}
					onClickNext={::this.gotoNext}
					currentImage={currentImage}
					isOpen={lightboxIsOpen}
				/>
			</Layout>
		)
	}
}

const FilterButton = ({active, children: label, onClick}) => {
	const className = `button ${active === label ? `active` : ``}`
	return (
		<button onClick={() => onClick(label)} className={className}>
			{label}
		</button>
	)
}

export default GalleryPage

export const query = graphql`
	{
		metadata: contentfulMetadata(title: {eq: "Gallery"}) {
			...ContentfulMetadataFragment
		}
		gallery: contentfulList(name: {eq: "Gallery Page Images"}) {
			items {
				... on ContentfulImage {
					id
					type
					image {
						file: resize(width: 1200) {
							src
							width
							height
						}
					}
				}
			}
		}
	}
`
